<template>
  <IAMPage v-if="Object.keys(contact).length">
    <div slot="title">
      <span>{{ title }}</span>
    </div>
    <template #extra>
      <ContactScore :score="contact.score" :contact-id="contact.id"/>
    </template>
    <div slot="actionbar">
      <div class="btn-group">
        <button
          type="button"
          title="Bekijk biedingen"
          class="btn btn-success"
          @click="showContactBids"
        >
          <i class="fas fa-gavel" />
          Biedingen
        </button>
        <router-link :to="{ name: 'ContactEdit', params: { id: contact.id } }" class="btn btn-default">
          <i class="fa fa-pencil" /> Bewerken
        </router-link>
      </div>
    </div>
    <div class="tabs-container">
      <ul class="nav nav-tabs" ref="tabBar">
        <router-link
          v-for="(link, index) in contactNavLinks"
          :key="index"
          :to="link.to"
          tag="li"
          data-toggle="tab"
          aria-expanded="false"
          active-class="active"
        >
          <a>{{ link.name }}</a>
        </router-link>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active">
          <div class="panel-body">
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <ContactBids ref="contactBidsModal" :contact-id="contactId" />
  </IAMPage>
</template>

<script>
import IAMPage from '@/components/iam/Page'
import ContactScore from '@/components/contacts/ContactScore.vue'
import ContactBids from '@/components/contacts/ContactBids'

import { mapActions, mapMutations, mapState } from 'vuex'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ContactDetailsLayout',
  components: {
    IAMPage,
    ContactScore,
    ContactBids
  },
  created () {
    this.init(this.contactId)
  },
  computed: {
    ...mapState('contacts', ['contact']),

    contactId () {
      return this.$route.params.id
    },
    title () {
      return `${this.contact.display_name}`
    },
    contactNavLinks () {
      return [
        {
          to: { name: 'ContactDetails' },
          name: 'Contactgegevens'
        },
        {
          to: { name: 'ContactEntities' },
          name: 'Eigendommen'
        },
        {
          to: { name: 'ContactRentals' },
          name: 'Huurpanden'
        },
        {
          to: { name: 'ContactCommunication' },
          name: 'Communicatie'
        },
        {
          to: { name: 'ContactLeads' },
          name: 'Leads'
        },
        {
          to: { name: 'ContactFiles' },
          name: 'Bestanden'
        },
        {
          to: { name: 'Newsletters' },
          name: 'Nieuwsbrieven'
        },
        {
          to: { name: 'ContactProperties' },
          name: 'Mijn vastgoed'
        },
        {
          to: { name: 'ContactTagHistory' },
          name: 'Dewaele levenslijn'
        },
        {
          to: { name: 'ContactInvoices' },
          name: 'Facturen'
        }
      ]
    }
  },
  methods: {
    ...mapActions('contacts', ['loadContactById']),
    ...mapMutations('contacts', ['setContactScore']),

    showContactBids () {
      this.$refs.contactBidsModal.show()
    },

    async init (contactId) {
      try {
        const response = await Promise.all([this.loadContactById(contactId)])
        return response
      } catch (error) {
        errorModal('Kan het contact niet laden, gelieve opnieuw te proberen.')
      }
    },
    setScore (score) {
      this.setContactScore(score)
    }
  }
}
</script>
