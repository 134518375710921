var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Biedingen","max-width":"tw-max-w-5xl"},on:{"hide":_vm.onHide}},[_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"infiniteLoad":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"item-property",fn:function(ref){
var item = ref.item;
return [_c('EntityHoverable',{attrs:{"value":{
          type: 'property',
          id: item.property.id,
          reference: item.property.reference
        }},on:{"clicked":_vm.hide}})]}},{key:"item-amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.amount))+" ")]}}])},'DataTable',_vm.bids,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }